import React from 'react'
import { graphql } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll';
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import Parallax from '../components/Parallax'
import Stars from '../components/Stars'
import { Link } from 'gatsby'
import Boka from '../components/Boka'

import Image from '../components/Image'
import '../components/PostCard.css'
import '../components/PostSection.css'


// Export Template for use in CMS preview
export const HomePageTemplate = ({ title, subtitle, featuredImage, offertknapp, body }) => (
  <main className="Home">
    <PageHeader
      large
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
      offertknapp={offertknapp}
    />

    <section className="section">
      <div className="container introjk">
        <h2>Är du i behov av flyttstädning?</h2>
       <p><span role="img" aria-label="checkmark">✔️</span> Snabbt och pålitligt flyttstäd.<br />
       <span role="img" aria-label="checkmark">✔️</span> Fasta priser och städgaranti. <br />
      <strong><span role="img" aria-label="checkmark">✔️</span> Enkelt att boka online eller via telefon.</strong>
        
        </p>
        <div onClick={() => scrollTo('#priser')} className="Button">Priser</div>
      </div>
    </section>
<section className="section">
  <div className="container">
    <div className="PostSection">
      <div className="PostSection--Grid">
      <Link to="#bokaoss" className="PostCard">
        
      <div className="PostCard--ImageJK relative">
        <img  src="/images/icon-1.png" alt="Hink med skurborste" className="Content-Image"></img>
        </div>
        
          <div className="PostCard--Content">
          <h3 className="PostCard--Title">BOKA STÄDNING</h3>
          
          <div className="PostCard--Excerpt"><em>boka direkt via hemsidan hos flyttstädning Göteborg.</em></div>
          </div>
        </Link>
        <Link to="/om-oss-pa-flyttstadning-goteborg" className="PostCard">
        
        <div className="PostCard--ImageJK relative">
        <img  src="/images/icon-2.png" alt="En mopp" className="Content-Image"></img>
        </div>

      <div className="PostCard--Content">
      <h3 className="PostCard--Title">PÅLITLIG PERSONAL</h3>
        
        <div className="PostCard--Excerpt"><em>Ni är trygga med oss, gör som 100:tals andra och boka oss idag.</em></div>
      </div>
      </Link>
      <Link to="/vanliga-fragor" className="PostCard">
        
        <div className="PostCard--ImageJK relative">
        <img  src="/images/icon-7.png" alt="En galge med kläder" className="Content-Image"></img>
        
        </div>

      <div className="PostCard--Content">
      <h3 className="PostCard--Title">LÄS MER OM OSS</h3>
        
        <div className="PostCard--Excerpt"><em>Frågor? Du hittar svaren här.</em></div>
      </div>
      </Link>
      </div>
    </div>

  </div>
    
</section>
<section className="section">
  <div className="container">
    <div className="PostSection">
      <div className="PostSection--GridJK">
      <div className="PostCard--HomeP PostCard--Image-Hide relative"><Image background title="Skinande rent tack vare Flyttstädning Göteborg" src="/images/flyttstad-gote.webp" alt="Skinande rent tack vare Flyttstädning Göteborg" /></div>
      
      <div>
     <h3>Vad ingår i flyttstädning Göteborg tjänster</h3>

      <ul >
        <li className="bullet-jk">Dammsugning och våttorkning av golv.</li>
        <li className="bullet-jk">Dörrkarmar, dörrhandtag, trösklar och dörrar torkas av.</li>
        <li className="bullet-jk">Fönsterputsning, in- och utvändigt.</li>
        <li className="bullet-jk">Rengöring av fönsterkarmar.</li>
        <li className="bullet-jk">.. och mer!</li>
      </ul>
      <Link to="/detta-ingar-i-flyttstadningen/"><div className="Button">Läs mer om vad som ingår</div></Link>
      </div>
         
      </div>
    </div>
  </div>
</section>    
<section className="section">
  <div className="container">
    <div className="PostSection">
      <div className="PostSection--GridJK">
      <div>
     <h3>Flyttstädning Göteborg</h3>

<p>Vi är ett städföretag som utför flyttstädning i Göteborg och vi har lång erfarenhet och ett brett kunnande inom flyttstädning. När du beställer din flyttstädning från oss är du garanterad att det blir absolut rent till ett riktigt bra pris.<br></br>
Vi utför allt städarbete efter Mäklarsamfundets riktlinjer vilket är en garanti för att flyttstädningen blir perfekt och klarar mäklarens/hyresvärdens besiktning.<br></br>
När du ska flytta är det många saker som du behöver fokusera på och om du överlämnar din flyttstädning till oss på behöver du absolut inte tänka på städningen. Vi erbjuder dessutom konkurrenskraftiga priser som blir ännu lägre när du utnyttjar RUT-avdraget.
</p>
      </div>
      <div className="PostCard--HomeP relative"><Image background title="En städare" src="/images/service-6.jpg" alt="Flyttstädning Göteborg hjälper dig flyttstäda" /></div>
         
      </div>
    </div>
  </div>
</section>
<section className="section">
  <div className="container">
    <div className="PostSection">
      <div className="PostSection--GridJK">
      <div className="PostCard--HomeP PostCard--Image-Hide relative"><Image background title="Flyttstädning Göteborgs resultat" src="/images/rent_rum.webp" alt="Flyttstädning Göteborgs resultat" /></div>
      
      <div>
     <h3>Pålitlig och ansvarsfull flyttstädning</h3>
     <p>Livet är fyllt av jobbiga och stressiga moment. Stress för också med sig onödiga saker såsom tjafs och bråk med dina närstående. Att städa boendet inför en flytt kan vara en sådan sak.</p>
     <p>Varför inte slippa den onödiga stressen och ge flyttstädning Göteborg fullt ansvar över städningen?</p>

     <p>Att städa tar lång tid och för att det nya ägarna av bostaden ska bli nöjda och glada, så kan det vara bra att ge över ansvaret till några som har lång erfarenhet för att bästa resultat ska uppnås.</p>
    <p>Ring eller mejla till oss för ytterligare information och bokning. Vi svarar på ditt mejl inom 24 timmar, under årets alla dagar.<br>
    </br>Vi ser fram emot att besvara dina frågor via mail, <a href="mailto:info@xn--flyttstdning-gteborg-hzb71b.com">info@flyttstädning-göteborg.com</a>, eller på telefon <a href="tel:073 637 99 08">073-6379908</a>  </p>
     
      </div>
         
      </div>
    </div>
  </div>
</section>
<section className="section">
  <div className="container">
    <div className="PostSection">
      <div className="PostSection--GridJK">
      <div>
     <h3>Flyttstäd med Garanti</h3>

<p>Vi på flyttstädning Göteborg lämnar självklart alltid garanti på vårat flyttstäd. Garantin gäller för både köparen och säljaren av bostaden.<br></br>

Vissa saker måste ni som kund dock se till att ordna innan flyttstädningen:</p>
<ul>
<li>Tillgång till vatten</li>
<li>Tillgång till el</li>
</ul>
<p>
Om det det ska rengöras bakom tvättmaskin, diskmanskiner eller torktumlare behövs dessa vara framdragna.
</p>
<Link to="/vanliga-fragor">   <div className="Button">Mer om våran garanti</div></Link>
      </div>
      <div className="PostCard--HomeP relative"><Image background title="Flyttstädning Göteborg städar din bostad" src="/images/ren_ugn.webp" alt="Flyttstädning Göteborg städar din bostad" /></div>
         
      </div>
    </div>
  </div>
</section>
<div id="priser"></div>
<Parallax />
<Stars />
<div id="bokaoss" style={{marginBottom:'60px'}}></div>
<section className="section">
<div className="container" >
<h3>Boka Oss På Flyttstädning Göteborg</h3>
<p>Fyll i formuläret nedan för att boka en flyttstädning. Alternativt ring <strong><a href="tel:073 637 99 08">073 637 99 08</a></strong>.</p>
</div>
<div className="container  Contact--Section1--Container">
<Boka name="Flyttstädning Göteborg" />
<div>
<h4>Varför vill ni veta mitt personnummer?</h4>
<p>
Ditt personnummer behövs om du bokar en flyttstädning med oss och vill utnyttja RUT-avdraget. Vi kan inte ansöka om utbetalning för din räkning från Skatteverket om vi inte har ditt personnummer. 
<strong> Det är Skatteverket som kräver den uppgiften, inte vi.</strong><br />

I de fall betalning sker mot faktura görs en kreditprövning av det faktureringsbolag som vi anlitar, och i detta fall krävs även personnummer.</p>

<h4>Vad menas med övriga städytor?</h4>
<p>
Det kan röra sig om exempelvis städytor som du vill ha städat eller få prisuppgift på, tex. balkong, vind, förråd, garage etc. – det vill säga ytor som inte ingår i boytan.</p>

<h4>Vilken övrig info ska jag fylla i?</h4>
<p>All information som kan vara relevant för oss att veta, tex. portkod, upplysningar om när överlåtelsen ska ske av objektet, i fall möbler ska finnas kvar när vi städar, om vissa ytor inte behöver rengöras, etc. Allt detta för att ditt flyttstäd ska bli så smidigt och kostnadseffektivt som möjligt.</p>

</div>
</div>
</section>
    <section className="section">
      <div className="container">
        <Content source={body} />
      </div>
     
    </section>
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}
          title= "test"
  >
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
        offertknapp
      }
    }
  }
`
