import React from 'react'
import './Stars.css'
import { Star } from 'react-feather'

export default () => (
  <div className="starz">
  
    
    <section className="Contact--Section1">
        <div className="container Contact--Section1--Container">
            <div className="Contact--Details" >
            <p className="recen" >Kundrecension - flyttstäd i Göteborg</p>
            <p className="namez">Carina</p>
            <div id="starcontainer" >
            <Star  className="feather"  /> <Star  className="feather"  /> <Star  className="feather"  /> <Star className="feather"  /> <Star className="feather"  />
            </div>
            <p className="comment">Vi var jättenöjda med städningen och besiktningsmannen var minst sagt imponerad!</p>
            </div>
            <div className="Contact--Details" >
            <p className="recen" >Kundrecension - flyttstäd i Göteborg</p>
            <p className="namez">Armin</p>
            <div id="starcontainer" >
            <Star  className="feather"  /> <Star  className="feather"  /> <Star  className="feather"  /> <Star className="feather"  /> <Star className="feather"  />
            </div>
            <p className="comment">Väldigt duktiga och väldigt trevliga. Rekommenderas varmt!</p>
            </div>
            <div className="Contact--Details" >
            <p className="recen" >Kundrecension - flyttstäd i Göteborg</p>
            <p className="namez">Simon</p>
            <div id="starcontainer" >
            <Star  className="feather"  /> <Star  className="feather"  /> <Star  className="feather"  /> <Star className="feather"  /> <Star className="feather"  />
            </div>
            <p className="comment">Trevligt bemötande vid bokning. Trevlig och väldigt seriös personal. Rekomenderas starkt! </p>
            </div>
       

        </div>
        </section>
      
   
  </div>
)
